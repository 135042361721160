import React from 'react';
import { Link } from 'react-router-dom'
function Home () {
  return (
    <div>
      <header className="masthead text-center text-white">
        <div className="masthead-content">
          <div className="container">
            <h1 className="masthead-heading mb-0">Sim, podemos te ajudar com isso!</h1>
            <h2 className="masthead-subheading mb-0">Precisando de serviços de TI?</h2>
            <a href="#servicos" className="btn btn-primary btn-xl rounded-pill mt-5">Veja nossos serviços</a>
          </div>
        </div>
      </header>
      <section>
        <div className="container" id="missao">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="p-5">
                <img className="img-fluid rounded-circle vtop" src="img/01.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="p-5">
                <h2 className="display-4">Nossa Missão</h2>
                <p>
                Nossa missão é crescer junto com nossos clientes, por meio de uma colaboração mútua.
                Buscamos sempre entregar o melhor serviço, garantindo a máxima disponibilidade e confiabilidade possível.
                </p>
                <p>
                Estamos abertos a novas ideias e flexíveis às mudanças que possam promover inovação e melhorias em nossos processos. 
                Nosso compromisso é fornecer soluções personalizadas e eficientes para atender às necessidades dos nossos clientes 
                 de maneira proativa e assertiva.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="somos" className="fix mtop-20">.</div>
        <div className="container" id="">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="p-5">
                <img className="img-fluid rounded-circle vtop" src="img/02.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-5">
                <h2 className="display-4">Quem Somos</h2>
                <p>
                Somos uma empresa dedicada a atender as necessidades dos nossos clientes de forma especializada. Temos como valores fundamentais a transparência e o comprometimento, pois acreditamos que esses são os pilares para um crescimento mútuo e sustentável.
                </p>
                <p>
                Valorizamos a colaboração e estamos sempre abertos a ouvir novas ideias, pois sabemos que todas podem ser aproveitadas de maneira positiva. Queremos trabalhar em conjunto com nossos clientes para alcançarmos objetivos comuns e contribuir para o sucesso dos seus negócios.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="servicos" className="fix mtop-20">.</div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-2">
              <div className="p-5">
                <img className="img-fluid rounded-circle vtop" src="img/03.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div className="p-5">
                <h2 className="display-4">Serviços</h2>
                <p>
                Estamos prontos para ajudar em qualquer projeto computacional que você tenha em mente, seja a criação de um website ou o desenvolvimento de um serviço de automação ou rastreamento em tempo real.
                </p>
                <p>
                Se você já tem uma solução em andamento, estamos disponíveis para conversar sobre possíveis melhorias que possam torná-la mais estável, confiável e disponível, sempre buscando garantir que o valor investido em recursos seja o mais adequado.
                </p>
                <p>
                Nossa equipe está comprometida em oferecer soluções personalizadas e eficientes, com foco na satisfação dos nossos clientes. Conte conosco para transformar suas ideias em realidade e levar seu projeto ao próximo nível.
                </p>
                <Link to="servicos">Veja nossos Serviços</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div id="contato" className="fix mtop-20">.</div>
        <div className="container" id="">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="p-5">
                <img className="img-fluid rounded-circle vtop" src="img/04.jpg" alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-5">
                <h2 className="display-4">Contato</h2>
                <div>
                  <h3>Envie uma mensagem para: </h3>
                  <span> contato arroba ragazzid.com.br</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Home;
