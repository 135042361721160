import React from 'react'

export default function TermosServico() {
  return (
    <section>
        <div id="exlusao" className="fix mtop-20">.</div>
    <div id="exlusao" className="fix mtop-20">.</div>
    <div className="container">
    <h1>Termos de Serviço</h1>

    <p>Estes Termos de Serviço ("Termos") regem o uso do aplicativo web RagazziD ("nós", "nosso" ou "aplicativo") por parte dos usuários. Ao utilizar o aplicativo RagazziD, você concorda com estes Termos. Se você não concordar com estes Termos, por favor, não use o aplicativo.</p>

    <h2>1. Uso do Aplicativo</h2>

    <p>1.1. O aplicativo RagazziD permite aos usuários encontrar informações sobre bares, restaurantes e casas noturnas, além de fornecer recursos para reservas, pedidos e outras funcionalidades relacionadas.</p>

    <p>1.2. Ao utilizar o aplicativo, você concorda em fornecer informações precisas e atualizadas durante o processo de registro e uso do serviço.</p>

    <p>1.3. Você é responsável por manter a confidencialidade de suas informações de login e por todas as atividades que ocorrerem em sua conta. Se suspeitar de qualquer uso não autorizado de sua conta, você deve nos notificar imediatamente.</p>

    <h2>2. Propriedade Intelectual</h2>

    <p>2.1. O aplicativo RagazziD e todo o conteúdo relacionado, incluindo textos, gráficos, logotipos, imagens, vídeos e software, são de propriedade exclusiva da empresa RagazziD e estão protegidos por direitos autorais e outras leis de propriedade intelectual.</p>

    <p>2.2. Você concorda em não copiar, modificar, distribuir, transmitir, exibir, vender, licenciar ou explorar comercialmente qualquer parte do aplicativo ou de seu conteúdo, exceto quando expressamente permitido por escrito pela empresa RagazziD.</p>

    <h2>3. Limitações de Responsabilidade</h2>

    <p>3.1. O aplicativo RagazziD é fornecido "como está" e não oferece garantias de qualquer tipo, expressas ou implícitas, incluindo, mas não se limitando a, garantias de comercialização, adequação a uma finalidade específica e não violação.</p>

    <p>3.2. A empresa RagazziD não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais, consequenciais ou punitivos decorrentes ou relacionados ao uso ou incapacidade de uso do aplicativo.</p>

    <h2>4. Disposições Gerais</h2>

    <p>4.1. Estes Termos constituem o acordo integral entre você e a empresa RagazziD em relação ao uso do aplicativo e substituem quaisquer acordos anteriores ou contemporâneos entre as partes.</p>

    <p>4.2. Estes Termos serão regidos e interpretados de acordo com as leis do [país/estado], sem considerar seus conflitos de disposições legais.</p>

    <p>4.3. A empresa RagazziD reserva-se o direito de modificar ou atualizar estes Termos a qualquer momento, mediante notificação prévia aos usuários.</p>

    <p>4.4. Se qualquer disposição destes Termos for considerada inválida ou inexequível, as demais disposições permanecerão em pleno vigor e efeito.</p>

    <h2>5. Contato</h2>

    <p>Se você tiver alguma dúvida ou preocupação relacionada a estes Termos de Serviço, entre em contato conosco através dos canais fornecidos no aplicativo RagazziD.</p>

    <p>Estes Termos de Serviço foram atualizados em 23/05/2023.</p>

    </div>
    </section>
  )
}
