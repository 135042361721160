import React from 'react'

export default function ExclusaoDadosUsuario() {
  return (
    <section>
    <div id="exlusao" className="fix mtop-20">.</div>
    <div id="exlusao" className="fix mtop-20">.</div>
    <div className="container">
    <h1>Exclusão de Dados do Usuário</h1>

    <p>Essa página descreve o processo de exclusão de dados pessoais dos usuários do aplicativo web RagazziD ("nós", "nosso" ou "aplicativo"). Se você deseja solicitar a exclusão dos seus dados pessoais armazenados em nosso aplicativo, siga as instruções abaixo.</p>

    <h2>1. Envio da Solicitação</h2>

    <p>1.1. Envie um e-mail para nossa equipe de suporte em contato@ragazzid.com.br com o assunto "Exclusão de Dados do Usuário".</p>

    <p>1.2. No corpo do e-mail, forneça as seguintes informações:</p>

    <ul>
        <li>Seu nome completo.</li>
        <li>Seu endereço de e-mail registrado no aplicativo.</li>
        <li>Quaisquer outros detalhes que possam ajudar na identificação de sua conta no aplicativo.</li>
    </ul>

    <h2>2. Verificação de Identidade</h2>

    <p>2.1. Para garantir a segurança e proteção dos dados, nossa equipe de suporte entrará em contato com você para verificar sua identidade antes de processar a exclusão dos dados.</p>

    <p>2.2. Responda prontamente às solicitações de verificação de identidade e forneça as informações adicionais solicitadas pela nossa equipe.</p>

    <h2>3. Processamento da Exclusão</h2>

    <p>3.1. Após a verificação de identidade, nossa equipe processará sua solicitação de exclusão de dados pessoais dentro de um prazo razoável.</p>

    <p>3.2. Observe que, após a exclusão dos dados, não poderemos recuperar ou restaurar suas informações pessoais, e você perderá acesso a quaisquer serviços ou recursos relacionados ao aplicativo.</p>

    <h2>4. Contato</h2>

    <p>Se você tiver alguma dúvida ou preocupação relacionada à exclusão de dados do usuário, entre em contato conosco através dos canais fornecidos no aplicativo RagazziD.</p>

    <p>Este documento de exclusão de dados do usuário foi atualizado em 23/05/2023.</p>
    </div>
    </section>
  )
}
