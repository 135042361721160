import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Home from './Pages/Home';
import Servicos from './Pages/Servicos';
import ExclusaoDadosUsuario from './Pages/ExclusaoDadosUsuario';
import Privacidade from './Pages/Privacidade';
import TermosServico from './Pages/TermosServico';
import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter as Router } from 'react-router-dom'


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/home" component={Home} />
      <Route path="/servicos" component={Servicos} />
      <Route path="/politica-privacidade" component={Privacidade} />
      <Route path="/termos-servico" component={TermosServico} />
      <Route path="/exclusao-dados-usuario" component={ExclusaoDadosUsuario} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
