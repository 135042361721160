import React from 'react'

export default function Privacidade() {
  return (
    <section>
    <div id="exlusao" className="fix mtop-20">.</div>
    <div id="exlusao" className="fix mtop-20">.</div>
    <div className='container'>
    <h1>Política de Privacidade</h1>

    <p>Esta Política de Privacidade descreve como o aplicativo web RagazziD ("nós", "nosso" ou "aplicativo") coleta, usa, armazena e protege as informações pessoais dos usuários. Ao utilizar o aplicativo RagazziD, você concorda com as práticas descritas nesta política.</p>

    <h2>1. Informações coletadas</h2>

    <h3>1.1 Informações pessoais</h3>
    <p>Podemos coletar informações pessoais fornecidas por você durante o processo de registro, como seu nome, endereço de e-mail, número de telefone e informações de pagamento.</p>

    <h3>1.2 Informações de uso</h3>
    <p>Podemos coletar informações sobre como você usa o aplicativo, incluindo suas interações com os recursos disponíveis, as páginas visitadas, as buscas realizadas e outras ações realizadas no aplicativo.</p>

    <h3>1.3 Informações de localização</h3>
    <p>Podemos coletar informações de localização quando você usa recursos que exigem acesso à localização, como encontrar bares e restaurantes próximos.</p>

    <h2>2. Uso das informações</h2>

    <h3>2.1 Fornecimento e personalização do serviço</h3>
    <p>Utilizamos as informações coletadas para fornecer o serviço oferecido pelo aplicativo, como ajudar você a encontrar bares, restaurantes e casas noturnas, fornecer informações relevantes sobre esses locais e facilitar reservas e pedidos.</p>

    <h3>2.2 Comunicação</h3>
    <p>Podemos usar suas informações para enviar comunicações relacionadas ao serviço, como confirmações de reservas, atualizações do aplicativo ou informações sobre promoções e eventos relevantes.</p>

    <h3>2.3 Melhoria do serviço</h3>
    <p>Utilizamos as informações coletadas para analisar o uso do aplicativo, identificar tendências e realizar melhorias no serviço, visando oferecer uma experiência cada vez mais personalizada e eficiente.</p>

    <h3>2.4 Segurança</h3>
    <p>As informações pessoais coletadas são armazenadas de forma segura e utilizadas para proteger nossa plataforma contra atividades fraudulentas, abusos ou violações dos termos de uso.</p>

    <h2>3. Compartilhamento de informações</h2>

    <h3>3.1 Parceiros de negócios</h3>
    <p>Podemos compartilhar informações não identificáveis pessoalmente com parceiros de negócios para fins de análise de dados, publicidade direcionada ou outros fins relacionados ao aprimoramento do serviço.</p>

    <h3>3.2 Requisitos legais</h3>
    <p>Podemos divulgar informações pessoais quando exigido por lei, processo legal ou solicitação governamental, ou quando acreditamos de boa-fé que a divulgação é necessária para proteger nossos direitos, sua segurança ou a segurança de terceiros.</p>

    <h2>4. Cookies e tecnologias semelhantes</h2>

    <p>O aplicativo RagazziD utiliza cookies e tecnologias semelhantes para coletar informações sobre seu uso do aplicativo e personalizar sua experiência. Essas tecnologias podem ser desativadas ou configuradas nas configurações do seu navegador ou dispositivo, no entanto, isso pode afetar a funcionalidade do aplicativo.</p>

    <h2>5. Segurança das informações</h2>

    <p>Empregamos medidas de segurança adequadas para proteger as informações pessoais dos usuários contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, é importante lembrar que nenhum método de transmissão pela Internet ou armazenamento eletrônico é completamente seguro. Portanto, não podemos garantir a segurança absoluta das informações.</p>

    <h2>6. Alterações nesta Política de Privacidade</h2>

    <p>Reservamo-nos o direito de atualizar esta Política de Privacidade periodicamente para refletir alterações em nossas práticas de privacidade. Recomendamos que você revise esta política regularmente para se manter informado sobre como protegemos suas informações pessoais.</p>

    <h2>7. Contato</h2>

    <p>Se você tiver alguma dúvida ou preocupação relacionada a esta Política de Privacidade, entre em contato conosco através dos canais fornecidos no aplicativo RagazziD.</p>

    <p>Esta Política de Privacidade foi atualizada em 23/05/2023.</p>
    </div>
    </section>
  )
}
