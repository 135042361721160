import React from 'react';

function Servicos () {
  return (
    <section>
      <div id="servicos" className="fix mtop-20">.</div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12 order-lg-1">
            <div className="p-5">
              <h2 className="display-4">Serviços</h2>
              <h3>Consultoria em Cloud</h3>
              <p className="small-text">
              Temos experiência e conhecimento especializado nas principais plataformas de computação em nuvem, tanto públicas quanto privadas. Nossa equipe está sempre atualizada com as últimas tecnologias e melhores práticas, a fim de oferecer as soluções mais eficientes e personalizadas para nossos clientes.

Seja qual for a plataforma de cloud que você prefira, podemos ajudá-lo a aproveitar ao máximo seus recursos e benefícios, garantindo maior segurança, escalabilidade e disponibilidade para suas aplicações e serviços. Conte conosco para fornecer as soluções mais adequadas às suas necessidades de negócios.
              </p>
              <h5>Alguns projetos </h5>
              <p className="small-text">
                Mantemos um sistema de alta disponibilidade que integra uma plataforma de reconhecimento facial, nossa
                tarefa é monitorar os serviços e escalar rapidamente conforme a necessidade.
              </p>
              <p className="small-text">
              Nós somos responsáveis por processar mais de 5 petabytes de dados semanalmente e torná-los pesquisáveis, com uma retenção de mais de 90 dias.

Nosso foco é garantir que os dados sejam gerenciados de forma eficiente e segura, permitindo que nossos clientes possam acessá-los facilmente e tomar decisões estratégicas com base neles. Temos experiência em lidar com grandes volumes de dados e implementar soluções escaláveis e confiáveis para gerenciá-los.

Estamos comprometidos em fornecer serviços de alta qualidade e garantir a satisfação dos nossos clientes. Conte conosco para gerenciar e processar seus dados com eficiência e segurança, permitindo que você possa se concentrar em seus negócios.
              </p>
              <h3>Automação de Processos</h3>
              <p className="small-text">
              Oferecemos soluções para automatizar processos e um de nossos cases de sucesso foi a automação do processo de atualização do estoque de veículos. Desenvolvemos uma solução customizada para nossos clientes que permite a atualização automática do estoque de veículos, garantindo maior eficiência e agilidade no processo.

Com a nossa solução, os clientes podem atualizar seus estoques de forma rápida e fácil, sem a necessidade de intervenção manual, o que reduz o tempo gasto nessa tarefa e minimiza o risco de erros. Além disso, nossa solução permite o acompanhamento em tempo real do estoque de veículos, proporcionando uma visão clara e precisa do status do inventário.

Estamos comprometidos em fornecer soluções personalizadas e eficientes para nossos clientes, e nossa equipe está sempre atualizada com as mais recentes tecnologias e melhores práticas. Conte conosco para automatizar seus processos e aumentar a eficiência do seu negócio.
              </p>
              <h3>Colocação em Busca</h3>
              <p className="small-text">
              Oferecemos serviços especializados em Search Engine Optimization (SEO) e estamos comprometidos em melhorar a visibilidade online dos nossos clientes. Utilizamos técnicas avançadas de SEO para aumentar a sua posição nos resultados de busca do Google e de outros motores de busca.

Nossa equipe de especialistas em SEO está sempre atualizada com as mais recentes tendências e melhores práticas em otimização de motores de busca, e trabalhamos em estreita colaboração com nossos clientes para entender suas necessidades e objetivos de negócios.

Através da nossa abordagem estratégica de SEO, ajudamos nossos clientes a aumentar o tráfego orgânico em seus sites, gerar mais leads e vendas, e melhorar sua presença online. Estamos comprometidos em fornecer serviços de alta qualidade e resultados mensuráveis para nossos clientes. Conte conosco para melhorar a sua posição nos resultados de busca e impulsionar o crescimento do seu negócio.
              </p>
              <h3>Redução de Custos</h3>
              <p className="small-text">
              Oferecemos soluções personalizadas para otimização de custos em serviços em nuvem (cloud) e estamos comprometidos em ajudar nossos clientes a reduzir seus custos operacionais. Utilizando nossa abordagem estratégica e expertise em serviços em nuvem, ajudamos a identificar pontos de custo alto e fornecemos sugestões de melhorias para reduzir seus custos.

Nossa equipe de especialistas em cloud está sempre atualizada com as mais recentes tecnologias e melhores práticas em serviços em nuvem, e trabalhamos em estreita colaboração com nossos clientes para entender suas necessidades e objetivos de negócios.

Através da nossa abordagem personalizada, ajudamos nossos clientes a otimizar seus gastos em serviços em nuvem, sem comprometer a qualidade ou eficiência dos serviços. Recentemente, conseguimos reduzir em até 60% os custos de cloud para um de nossos clientes.

Estamos comprometidos em fornecer soluções de alta qualidade e resultados mensuráveis para nossos clientes. Conte conosco para otimizar seus custos em serviços em nuvem e impulsionar o crescimento do seu negócio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Servicos;
